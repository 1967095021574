<template>
  <div>
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container
      :active="true"
      :show-header="false"
      v-model="params"
      @search="search"
      @reset="reset"
      show-search
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', 'master')">
            <b-button variant="primary" :to="{ name: 'create-master' }">{{
              $t("button.create")
            }}</b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(ipaddress)="data">
            <b-link class="link-underline" @click="viewIp(data.item)">
              {{ data.item.ipaddress }}
            </b-link>
          </template>
          <template #cell(profilePicture)="data">
            <b-avatar
              size="40"
              :src="data.item.profilePicture"
              :text="avatarText(data.item.name)"
              variant="light-primary"
              class="badge-minimal"
            >
              <feather-icon v-if="!data.item.name" icon="UserIcon" size="22" />
            </b-avatar>
          </template>
          <template #cell(givenCredit)="data">
            {{ data.item.givenCredit | currency(data.item.ccy) }}
          </template>
          <template #cell(avalBalance)="data">
            {{ data.item.avalBalance | currency(data.item.ccy) }}
          </template>
          <template #cell(cashBalance)="data">
            {{ data.item.cashBalance | currency(data.item.ccy) }}
          </template>
          <template #cell(username)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'list-agent',
                query: { parentId: data.item.id },
              }"
              >{{ data.item.username }}</b-link
            >
          </template>
          <template #cell(member)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'list-agent',
                query: { parentId: data.item.id },
              }"
              >{{ data.item.member }}</b-link
            >
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isEnable"
            >
              {{ $t(`status.active`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`status.inactive`) }}
            </b-badge>
          </template>
          <template #cell(lastActivityAt)="data">
            {{ data.item.lastActivityAt | formatDate }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate }}
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <!--              <b-button-->
              <!--                :to="{-->
              <!--                  name: 'view-master',-->
              <!--                  params: { id: data.item.id },-->
              <!--                }"-->
              <!--                v-b-tooltip.hover-->
              <!--                :title="$t('general.view')"-->
              <!--                variant="primary"-->
              <!--                pill-->
              <!--                size="sm"-->
              <!--              >-->
              <!--                <feather-icon icon="EyeIcon" />-->
              <!--              </b-button>-->
              <b-button
                v-if="$can('update', 'master')"
                :to="{
                  name: 'update-master',
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('general.update')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="$can('create', 'master')"
                :to="{
                  name: 'create-master',
                  query: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('button.clone')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
              <b-button
                v-if="$can('create', 'master') || $can('ediupdatet', 'master')"
                v-b-tooltip.hover
                @click="
                  showDepositOrWithdrawModel(
                    data.item.id,
                    data.item.username,
                    data.item.avalBalance,
                    data.item.ccy
                  )
                "
                :title="$t('button.deposit')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="CreditCardIcon" />
              </b-button>
              <b-button
                v-if="$can('create', 'master') || $can('update', 'master')"
                v-b-tooltip.hover
                @click="
                  showDepositOrWithdrawModel(
                    data.item.id,
                    data.item.username,
                    data.item.avalBalance,
                    data.item.ccy,
                    'withdrawal'
                  )
                "
                :title="$t('button.withdraw')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="RotateCcwIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
          ref="pagination"
        ></n-pagination>
      </div>
    </b-card>
    <deposit-or-withdraw
      ref="depositWithdrawModel"
      @success="depositOrWithdrawSuccess"
    ></deposit-or-withdraw>
    <ip-modal ref="ipModal" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NButtonDelete from "@/components/NButtonDelete";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import { avatarText } from "@core/utils/filter";
import DepositOrWithdraw from "@/views/account/depositOrWithdraw";
import IpModal from "@/views/ipModal/Index.vue";

const MasterRepository = Repository.get("master");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    BFormGroup,
    BInputGroup,
    BBadge,
    BLink,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    DepositOrWithdraw,
    IpModal,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
    activeSearch() {
      return (
        !!this.$route.query.name ||
        !!this.$route.query.username ||
        !!this.$route.query.isEnable ||
        !!this.$route.query.parentId
      );
    },
    userId: function () {
      if (this.$store.state.profile.id) {
        return this.$store.state.profile.id;
      }

      return this.userData?.id;
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        search: this.$route.query.search || "",
        parentId: Number(this.$route.query.parentId) || null,
      },
      avatarText,
    };
  },
  mounted() {
    this.getData(this.params.page);
  },
  methods: {
    viewIp(item) {
      this.$refs.ipModal.show(item);
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData(this.params.page);
    },
    search(searchText) {
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.params.search = searchText;
      this.updateQuerySting();
      this.getData(this.params.page);
    },
    reset() {
      this.$refs.search.reset();
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.params.search = "";
      this.updateQuerySting();
      this.getData(this.page);
    },
    spliceItem(id) {
      const deleteIndex = this.items.findIndex((item) => {
        return item.id === id;
      });

      this.items.splice(deleteIndex, 1);
      this.total -= 1;
    },
    deleteItem(id) {
      MasterRepository.delete(id)
        .then((response) => {
          this.spliceItem(id);
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      MasterRepository.index({
        ...this.params,
        searchFields: this.searchFields,
      })
        .then((response) => {
          let data = response.data.data.list;
          this.items = [...data];
          this.total = response.data.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showDepositOrWithdrawModel(
      id,
      username,
      availableCredit,
      ccy,
      type = "deposit"
    ) {
      this.$refs.depositWithdrawModel.changeData({
        type: type,
        username: username,
        amount: 0,
        userId: id,
        remark: null,
        availableCredit: availableCredit,
        ccy: ccy,
      });

      this.$refs.depositWithdrawModel.show(
        `${this.$t("field.deposit")} (${username})`
      );
    },
    depositOrWithdrawSuccess() {
      this.getData();
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    return {
      fields,
      searchFields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
